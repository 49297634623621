import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import IosShareIcon from "@mui/icons-material/IosShare.js";
import {CardContent, CardHeader, Chip, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import GoogleIcon from "@mui/icons-material/Google.js";
import AppleIcon from "@mui/icons-material/Apple.js";
import React from "react";
import {copyToClipboard} from "./Event.jsx";
import {display24HourTime} from "../shared/date.js";

export const EventCard = (props) => {
    const {v, events, user, date} = props;
    const href = `https://austin2step.com/event/${v.key}/${date}`

    return  <Card>
            <Button onClick={e => copyToClipboard(e, href, user)} href={href} size={"small"} style={{float: "right", margin: "20px"}} variant="contained" endIcon={<IosShareIcon />}>Share</Button>
            <CardHeader title={v.name} subheader={v.location} />
            <CardContent>
                <div>
                    <Typography variant={"overline"}>Lineup for {date}</Typography>
                </div>
                <div style={{paddingBottom: "20px"}}>
                    <div>
                        {/*{user.loggedIn && <RSVPButton style={{marginTop: "3px"}} date={rsvpDate}*/}
                        {/*                              venueKey={venue.venueKey}/>}*/}
                    </div>
                    <div>
                        {events.map((slots, i) => <div
                            key={i}><strong>{display24HourTime(slots.time)}</strong> {slots.danceable ?
                            <strong>
                                <Link href={"/artist/" + slots.bandKey}>{slots.band}</Link>
                            </strong> : slots.band}
                            {slots.tag && <span> <i>{slots.tag}</i> </span>}
                            {slots.venmo &&
                                <Chip onClick={e => user.setTipBoxData({band: slots.band, url: slots.venmo})}
                                      size={"small"} color={"info"} label={"tip"} component={"a"}
                                      clickable/>}</div>)}
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <Typography variant={"overline"}>Getting Here</Typography>
                    </div>
                    <div><small>{v.address}</small></div>
                    <div><small>{v.city}, {v.state} {v.zip}</small></div>
                    <div>
                        <Button startIcon={<GoogleIcon />} href={v.googleMap()} variant={"outlined"} target="_blank">Maps</Button>&nbsp;
                        <Button startIcon={<AppleIcon />} href={v.appleMap()} variant={"outlined"} target="_blank">Maps</Button>
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <Typography variant={"overline"}>Other</Typography>
                    </div>
                    <div><small>Cover: {v.cover}</small></div>
                    <div><small>Ages: {v.age}</small></div>
                </div>
            </CardContent>
        </Card>
}