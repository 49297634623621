import React, {useContext, useEffect, useState} from "react";
import UserContext from "./UserContext.jsx";
import {getEvents} from "../EventLoader.jsx";
import Grid from "@mui/material/Grid";
import {BackButton} from "./BackButton.jsx";
import {EventCard} from "./EventCard.jsx";
import {fetchVenue} from "../fetch.js";

export const EventPage = (props) => {

    const {venueKey, date} = props.match.params;
    let [events, setEvents] = useState([]);
    let [venue, setVenue] = useState(null)
    const user = useContext(UserContext);

    useEffect(() => {
        getEvents((e) => setEvents(e))
        fetchVenue(venueKey)
            .then(v => setVenue(v));
    }, [])

    events = events.filter(e => e.venueKey === venueKey && e.date === date)



    return <Grid container>
            <Grid item xs={12}>
                <BackButton />
                {venue &&
                <EventCard v={venue} events={events} user={user} date={date}/>}
            </Grid>
        </Grid>
}