import {Venue} from "./shared/venue.js";

export const api_host = process.env.REACT_APP_API_HOST;

export const fetchVenue = (venueKey) => fetch(api_host + "/?action=VenueGet&venueKey=" + venueKey)
    .then(res => res.json())
    .then(o => {
        const {venue_key, name, cover, location, age, address, city, state, zip} = o;
        return new Venue(venueKey, name, cover, location, age, address, city, state, zip)
    })
    .catch(e => {
        console.log(e);
        return null;
    })

export const getRSVPCount = () => fetch(api_host + "/?action=RSVPCount")
    .then(res => res.json())

export const saveVenue = (venue) => fetch(api_host + "/?action=VenueSet",
    {
        method: "POST",
        body: JSON.stringify(venue)
    }
)

export const getAllVenues = () => fetch(api_host + "/?action=VenueGetAll")
    .then(res => res.json())

export const calendarEventSave = async (calendarEvent, jti) =>
    await fetch(api_host + "/?action=CalendarEventSave&jti=" + jti, {
        method: "POST",
        body: JSON.stringify(calendarEvent)
    })
        .then(res => res.json())
        .then(body => JSON.parse(body.event))
        .then(event => event.groupUUID)
        .catch(e => false)

export const calendarEventGet = (jti, groupUUID) =>
    fetch(api_host + `/?action=CalendarEventGet&jti=${jti}&groupUUID=${groupUUID}`)
        .then(res => res.json())

export const calendarUserEventListGet = (jti) =>
    fetch(api_host + `/?action=CalendarUserEventListGet&jti=${jti}`)
        .then(res => res.json())

export const getPrivateToken = (jti) =>
    fetch(api_host + "/?action=privateTokenGet&jti=" + jti)
        .then(res => res.json())
        .then(res => res.token)
        .catch(e => false)

export const deleteUserEvent = (jti,groupUUID) =>
    fetch(api_host + `/?action=CalendarEventDelete&jti=${jti}&groupUUID=${groupUUID}`)
        .then(res => res.status === 200)