import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {CardHeader, FormControl, Link, TextField} from "@mui/material";
import {useState} from "react";
import Button from "@mui/material/Button";
import {api_host} from "../fetch.js"
import Alert from "@mui/material/Alert";

export const VenueAddPage = (props) => {

    let [venueName, setVenueName] = useState("")
    let [city, setCity] = useState("Austin")
    let [saving, setSaving] = useState(false)
    let [venueKey, setVenueKey] = useState();

    const handleSave = () => {
        setSaving(true)
        fetch(api_host + `/?action=VenueCreate&venueName=${venueName}&city=${city}`)
            .then(res => res.json())
            .then(res => {
                setVenueKey(res.venueKey)
                setSaving(false)
                setCity("Austin")
                setVenueName("")
            })
    }

    if (venueKey) {
        return <Card>
            <CardContent>
                <Alert>{venueName} added</Alert>
                <Link href={"/event/add"}>Add new event</Link>
            </CardContent>

        </Card>
    }

    return <Card>
        <CardHeader title={"Add New Venue"} />
        <CardContent>
            <div>
                <FormControl>
                    <TextField label={"name"} required onChange={e => setVenueName(e.target.value)} />
                </FormControl>
            </div>
            <div>
                <FormControl>
                    <TextField label={"city"} required onChange={e => setCity(e.target.value)} />
                </FormControl>
            </div>
            <div>
                <Button onClick={handleSave} variant={"contained"} disabled={saving}>Submit</Button>
            </div>
        </CardContent>
    </Card>
}