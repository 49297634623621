import {dateToHumanDateStr, display24HourTime} from "../date.js";

export class CalendarEvent {
    constructor(venueKey = "", venueName= "", date = "", slots = [], groupUUID = "", creatorSub = "", creatorName = "", status = "", creator = "") {
        this.venueKey = venueKey
        this.venueName = venueName
        this.date = date
        this.slots = slots
        this.groupUUID = groupUUID
        this.creatorSub = creatorSub
        this.creatorName = creatorName
        this.status = status
        this.creator = creator
    }

    localDate = () => {
        const date = new Date(this.date + "T12:00:00")
        return dateToHumanDateStr(date)
    }

    key = () => {
        return this.date + '-' + this.venueKey
    }
}

export class CalendarEventSlot {
    constructor(time, type, artist) {
        this.time = time
        this.type = type
        this.artist = artist
    }

    localTime = () => {
        return display24HourTime(this.time)
    }
}

export class CalendarEventList extends Array {

    static fromRows = (rows) => {
        const eventMap = {}

        rows.forEach(r => {
            const {date, venueKey, venueName, time, eventType, bandName, groupUUID, sub, creatorName, status, creator} = r;
            const key = `${date}-${venueKey}`
            if (!eventMap.hasOwnProperty(key)) {
                eventMap[key] = new CalendarEvent(venueKey, venueName, date, [], groupUUID, sub, creatorName, status, creator)
            }
            eventMap[key].slots.push(new CalendarEventSlot(time, eventType, bandName))
        })

        const sortFn = (a,b) => {
            if (a.date !== b.date) {
                return a.date < b.date ? -1 : 1
            }
            return a.venueKey < b.venueKey ? -1 : 1
        }

        const eventList = new CalendarEventList(...Object.values(eventMap).sort(sortFn))

        return eventList
    }
}

