import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {CardHeader, Link, Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import React from "react";

const desc = "How your information is used and stored by Austin 2 Step"

const title = "Privacy Policy"

export const PrivacyPage = () => <div>
    <Link href={"/"}>Home</Link>
     <Helmet>
        <meta charSet="utf-8"/>
        <meta property="og:description" content={desc}/>
        <meta property="twitter:title" content={title}/>
        <meta property="twitter:description" content={desc}/>
        <title>{title}</title>
        <meta property="description" content={desc} />
        <link rel="canonical" href="https://austin2step.com/privacy"/>
    </Helmet>
    <Card>
        <CardHeader title={"Privacy Policy"} />
        <CardContent>
            <Typography variant={"h5"}>Cookies</Typography>
            <Typography variant={"body2"}>Cookies are used for 2 different things on this site, for internal purposes and for site analytics.</Typography>
            <Typography variant={"body2"}>Internal purposes include remembering your filter settings on the dance calendar and keeping you logged in between sessions. Otherwise you would have to constantly input these things and thats annoying.</Typography>
            <Typography variant={"body2"}>The 3rd party cookies from Google are used for site analytics. This allows the site to see which pages are being accessed and what kind of devices are being used. There is no personally identifiable information associated with tracking.</Typography>
            <br />

            <Typography variant={"h5"}>Google Authentication</Typography>
            <Typography variant={"body2"}>Google Auth is used to handle logins. By default, Google shares 3 pieces of information from your login: Your name, email and Google Account ID. The last piece of info there, the Google Account ID, is used to tie different data from the site such as your RSVP's and events created to your account.</Typography>
            <Typography variant={"body2"}>This data provided by Google Auth is used for site functionality only, and IS NOT used for any of the following:</Typography>
            <Typography variant={"body2"}><ul>
                <li>Marketing / Spam</li>
                <li>Sold / Disclosed to 3rd parties</li>
                <li>Displayed on the website without your explicit consent</li>
            </ul></Typography>

        </CardContent>
    </Card>
</div>