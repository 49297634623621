import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {Box, FormControl, TextField} from "@mui/material";
import {fetchVenue,saveVenue} from "../fetch.js";
import Button from "@mui/material/Button";

const api_host = process.env.REACT_APP_API_HOST;

const style = {marginBottom: "10px", display: "block"}

const VenueForm = () => {

    const params = useParams();
    const venue_key = params.venue_key;

    let [name, setName] = useState("");
    let [address, setAddress] = useState("");
    let [city, setCity] = useState("");
    let [state, setState] = useState("");
    let [zip, setZip] = useState("");
    let [location, setLocation] = useState("");
    let [cover, setCover] = useState("");
    let [age, setAge] = useState("");

    useEffect(() => {
        fetchVenue(venue_key)
            .then(v => {
                setName(v.name)
                setAddress(v.address)
                setCity(v.city)
                setState(v.state)
                setZip(v.zip)
                setLocation(v.location)
                setCover(v.cover)
                setAge(v.age)
            })
    }, [])

    return <Grid container>
            <Grid item xs={12}>
                <Box component={"form"} sx={{ '& .MuiTextField-root': { m: 1, width: '50ch' } }}>

                    <div>
                        <TextField label={"Name"} value={name} onChange={e => setName(e.target.value)} variant={"filled"} required/>
                    </div>
                    <div>
                        <TextField label={"Street Address"} value={address} onChange={e => setAddress(e.target.value)} variant={"filled"} required/>
                    </div>
                    <div>
                        <TextField label={"City"} value={city} onChange={e => setCity(e.target.value)}variant={"filled"} required/>
                    </div>
                    <div>
                        <TextField label={"State"} value={state} onChange={e => setState(e.target.value)} variant={"filled"} required/>
                    </div>
                    <div>
                        <TextField label={"Zip"} value={zip} onChange={e => setZip(e.target.value)} variant={"filled"} required/>
                    </div>
                    <div>
                        <TextField label={"Location"} value={location} onChange={e => setLocation(e.target.value)} variant={"filled"}/>
                    </div>
                    <div>
                        <TextField label={"Cover"} value={cover} onChange={e => setCover(e.target.value)} variant={"filled"}/>
                    </div>
                    <div>
                        <TextField label={"Ages"} value={age} onChange={e => setAge(e.target.value)} variant={"filled"}/>
                    </div>
                    <div>
                        <Button onClick={() => saveVenue({venue_key: venue_key, name: name, cover: cover, location: location, age: age, address: address, city: city, state: state, zip: zip})}>Save</Button>
                    </div>
                </Box>
            </Grid>
        </Grid>
}

export default VenueForm;