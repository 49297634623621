import util from "util";

export const getEveryDayMap = (startDateObj, endDateObj, excludeDaysOfWeek = []) => {
    let allDateMap = {};


    //add in first
    if (!excludeDaysOfWeek.includes(startDateObj.getDay())) {
        allDateMap[toDateStr(startDateObj)] = true;
    }

    //add in rest
    while (startDateObj.getTime() < endDateObj.getTime()) {
        startDateObj = new Date(startDateObj.getTime() + 86400000);
        if (!excludeDaysOfWeek.includes(startDateObj.getDay())) {
            allDateMap[toDateStr(startDateObj)] = true;
        }
    }

    return allDateMap;
}

export const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const monNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const toDateStr = (dateObj) => util.format("%s-%s-%s",dateObj.getFullYear(), (dateObj.getMonth() + 1).toString().padStart(2, "0"), dateObj.getDate().toString().padStart(2,"0" ));
export const toYYYYMMDD = (dateObj) => util.format("%s-%s-%s",dateObj.getFullYear(), (dateObj.getMonth() + 1).toString().padStart(2, "0"), dateObj.getDate().toString().padStart(2,"0" ));
export const toHHMM = (dateObj) => util.format("%s:%s",dateObj.getHours().toString().padStart(2,"0"), dateObj.getMinutes().toString().padEnd(2,"0"));
export const dateToHumanDateStr = dateObj => dayNames[dateObj.getDay()] + ", " + monNames[dateObj.getMonth()] + " " + dateObj.getDate();
export const display24HourTime = time => {
    let [h,m] = time.split(":")
    let a= "";
    h = parseInt(h)
    if (h > 12) {
        h = h % 12
    }
    if (m === "00") {
        m = ""
    } else {
        m = ":" + m
    }
    return h.toString() + m
}