import {useContext, useEffect, useState} from "react";
import {calendarUserEventListGet, deleteUserEvent} from "../fetch.js";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {CardActions, CardHeader, Stack, Typography} from "@mui/material";
import UserContext from "./UserContext.jsx";
import {CalendarEventList} from "../shared/class/event.js";
import Button from "@mui/material/Button";
import {toYYYYMMDD} from "../shared/date.js";
import Switch from "@mui/material/Switch";

const Loading = () => <Card>
    <CardHeader title={"Loading Your Events"} />
    <CardContent>
        ...
    </CardContent>
</Card>

const NoEvents = () => <Card>
    <CardHeader title={"No Events Found"} />
    <CardContent>
        Click on the "Add New" Button above to add an event.
    </CardContent>
</Card>

export const AccountEventsPage = (props) => {

    const user = useContext(UserContext)
    const [eventList, setEventList] = useState(new CalendarEventList())
    const [loading, setLoading] = useState(true)
    const [displayPastEvents, setDisplayPastEvents] = useState(false)

    const loadEvents = () => calendarUserEventListGet(user.jti)
        .then(res => CalendarEventList.fromRows(res))
        .then(res => setEventList(res))
        .then(() => setLoading(false))

    useEffect(() => {
        loadEvents()

    }, [])

    const deleteEvent = async (groupUUID) => {
        deleteUserEvent(user.jti, groupUUID)
            .then(success => {
                console.log("SUCCESS", success)
                success ?
                    user.setSnackBar({message: "Event Deleted", open: true}) :
                    user.setSnackBar({message: "Delete Failed", open: true})
                }
           ).then(() => loadEvents())
    }
    const today = toYYYYMMDD(new Date())
    let eventListFiltered;
    if (displayPastEvents) {
        eventListFiltered = eventList.filter(e => e.date < today)
    } else {
        eventListFiltered = eventList.filter(e => e.date >= today)
    }

    const EventCards = () => eventListFiltered.map((e,i) =>
            <div style={{margin: "10px 0px 10px 0px"}} key={i}>
                <Card>
                    <CardHeader title={e.localDate()} />
                    <CardContent>
                        <div>{e.venueName}</div>
                        {e.slots.map((s,j) =>
                            <div key={j}>{s.localTime()} - {s.artist}</div>
                        )}
                    </CardContent>
                    <CardActions>
                        <Button size={"small"} variant={"contained"} href={"/event/edit?groupUUID=" + e.groupUUID}>Edit</Button>
                        <Button size={"small"} color={"error"} onClick={() => deleteEvent(e.groupUUID)}>Delete</Button>
                    </CardActions>
                </Card>
            </div>)

    const EventCardContainer = () => {
        if (loading) {
            return <Loading />
        }
        if (eventListFiltered.length === 0) {
            return <NoEvents />
        }
        return <EventCards />
    }

    return <div>
        <Card>
            <CardHeader title={"Events you created"} />
            <CardContent>
                These are the upcoming events you created. These are editable by you but not by other users.
            </CardContent>
            <CardActions>
                <Button size={"small"} variant={"contained"} href={"/event/edit?id=new"}>Add New</Button>
            </CardActions>
        </Card>
        <div>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography>Past</Typography>
                <Switch checked={!displayPastEvents} onClick={e => setDisplayPastEvents(!e.target.checked)} />
                <Typography>Upcoming</Typography>
              </Stack>
        </div>
        <EventCardContainer />
        </div>
}