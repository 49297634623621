import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {getEvents, mapEvents} from "../EventLoader.jsx";
import {EventCard} from "./EventCard.jsx";
import {venueMap} from "../shared/venue.js";
import UserContext from "./UserContext.jsx";
import {BackButton} from "./BackButton.jsx";
import Card from "@mui/material/Card";
import {CardHeader} from "@mui/material";
import {dateToHumanDateStr} from "../shared/date.js";

const DayPage = (props) => {

    const user = useContext(UserContext);
    const rsvpDate = props.match.params.date;
    const humanDate = dateToHumanDateStr(new Date(rsvpDate + "T12:00:00"))

    let [events, setEvents] = useState([])
    events = events.filter(e => e.date === rsvpDate);
    if (events.length > 0) {
        events = mapEvents(events)[0].data;
    }

    useEffect(() => {
        getEvents((e) => setEvents(e))
    }, []);

    return (
        <div>
            <BackButton />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={`${humanDate} Detailed Calendar`} />
                    </Card>
                </Grid>
                {events.map((e) => <Grid item xs={12} key={e.venueKey}>
                    <EventCard v={venueMap[e.venueKey]} events={e.data} user={user} date={rsvpDate}/>
                 </Grid>)}
            </Grid>
        </div>
    );
}

export default DayPage;