import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close.js";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import ReactGA from "react-ga4";

const tipLogger = (url) => ReactGA.event({
    category: 'User',
    action: 'Tip',
    label: url
})

export const TipBox = params => {
    return <Dialog open={params.tipBoxData !== false}  width={600} >
        <DialogTitle>Tip {params.tipBoxData.band}</DialogTitle>
        <CloseIcon style={{position:"absolute",top:8,right:8}} onClick={() => params.setTipBoxData(false)}/>
        {/*<Chip style={{float: "right"}} color={"info"} clickable icon={<CloseIcon />} label={"close"} />*/}
        <DialogContent dividers>
            Click the "TIP NOW" Button below. You will be redirected to band's Venmo account.
            <br />
            <br />
            <Typography variant={"caption"}>This band tip data has been provided by <Link href={"http://www.tipthebandatx.live/"} target="_blank">Tip the Band ATX</Link></Typography>
        </DialogContent>
       <DialogActions>
           <Button variant={"contained"} href={params.tipBoxData.url} onClick={() => tipLogger(params.tipBoxData.url)} target="_blank">TIP NOW</Button>
       </DialogActions>
    </Dialog>
}