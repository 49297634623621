import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {
    CardHeader,
    Link,
    Select,
    Snackbar,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField
} from "@mui/material";
import {dateToHumanDateStr, display24HourTime, toYYYYMMDD} from "../shared/date.js";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from '@mui/icons-material/Add';
import {getAllVenues, calendarEventSave, calendarEventGet} from "../fetch.js";
import {CalendarEvent} from "../shared/class/event.js";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import userContext from "./UserContext.jsx";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {validate} from "uuid";

const timeSlots = Array.from(Array(26).keys()).slice(9).reduce((acc, v) => {
    const hour = (v % 12 === 0 ? 12 : v % 12).toString()
    const hourKey = v.toString().padStart(2,"0")
    const meridian = v > 11 && v < 24 ? "pm" : "am"

    acc.push({
        key: `${hourKey}:00`,
        label: `${hour}:00 ${meridian}`
    })

    acc.push({
        key: `${hourKey}:30`,
        label: `${hour}:30 ${meridian}`
    })

    return acc;
}, []).map((v, i) => <MenuItem value={v.key} key={i}>{v.label}</MenuItem>

)

// const hourOptions = timeSlots.map((v,i) =>
//     <MenuItem value={v.hourKey} key={i}>{v.hourLabel} {v.meridian}</MenuItem>
// );
// const minuteOptions = minuteSlots.map((v,i) =>
//     <MenuItem value={v} key={i}>{v}</MenuItem>
// )

const mergeSlots = (slots, i, k, v) => {
    const s = [...slots]
    s[i] = Object.assign({}, slots[i])
    s[i][k] = v
    return s
}

const deleteSlot = (s, i) => {
    let slots = [...s]
    slots.splice(i,1)
    return slots
}

const bandLessonLabel = (type) =>
    type === "band" ?
        "Band Name" :
        "Lesson Name"

const CalendarEventSlot = (params) => <div>
    <Select value={params.slot.type} onChange={e => params.setSlots(mergeSlots(params.slots, params.i, "type", e.target.value))}>
        <MenuItem value={"band"}>Band</MenuItem>
        <MenuItem value={"lesson"}>Lesson</MenuItem>
    </Select>
    <Select value={params.slot.time} onChange={(v) => params.setSlots(mergeSlots(params.slots, params.i, "time", v.target.value))}>
        {timeSlots}
    </Select>
    <TextField value={params.slot.artist} variant={"filled"} label={bandLessonLabel(params.slots[params.i].type)} onChange={(v) => params.setSlots(mergeSlots(params.slots, params.i, "artist", v.target.value))}/>
    <IconButton onClick={()=>params.setSlots(deleteSlot(params.slots, params.i))}><DeleteIcon /></IconButton>
</div>

const defaultSlot = {"time": "21:00", "type": "band", "artist": ""}

export const EventEditPage = (props) => {

    const user = useContext(userContext);
    const params = new URLSearchParams(window.location.search)

    const [activeStep, setActiveStep] = useState(0)
    const [venueName, setVenueName] = useState("")
    const [venueKey, setVenueKey] = useState("")
    const [date, setDate] = useState(params.has("date") ? params.get("date") : toYYYYMMDD(new Date()))
    const [venueList, setVenueList] = useState([])
    const [slots, setSlots] = useState([defaultSlot]);
    // const [insta, setInsta] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [snackBarMessage, setSnackBarMessage] = useState("")
    const [groupUUID, setGroupUUID] = useState(false)

    const handleSubmit = async (e, event) => {
        setLoading(true)
        calendarEventSave(event, user.jti)
            .then(gu => {
                if (gu) {
                    setSuccess(validate(gu))
                    setGroupUUID(gu)
                } else {
                    setSuccess(false)
                }
                setLoading(false)
                setSnackBarOpen(true)
                setSnackBarMessage(gu ? "Event Saved: " + gu : "Save Failed")
            })
    }

    const event = new CalendarEvent(venueKey, venueName, date, slots, groupUUID, user.sub)

    const StepControl = params => <div>
        {activeStep > 0 && <Button disabled={loading} onClick={()=> setActiveStep(activeStep - 1)}>Back</Button>}
        {activeStep < 3 && <Button disabled={params.waiting} variant={"contained"} onClick={()=> setActiveStep(activeStep + 1)}>Next</Button>}
        {/*{activeStep == 3 && <Button variant={"contained"} onClick={()=> setActiveStep(activeStep + 1)}>Next</Button>}*/}
        {activeStep === 3 && <Button disabled={loading} variant={"contained"} onClick={(e) => handleSubmit(e, event, user.jti)}>Submit</Button>}
    </div>


    useEffect(() => {
        //load all venues to populate for search box
        const loadVenues = () => {
            getAllVenues()
                .then(venues => setVenueList(venues))
        }
        loadVenues();

        const getEvent = () => {
            calendarEventGet(user.jti, params.get("groupUUID"))
                .then(events => {
                    const s = []

                    if (events.length === 0) {
                        return
                    }

                    events.forEach(e => {
                        setVenueKey(e.venueKey)
                        setVenueName(e.venueName)
                        setDate(e.date)
                        setGroupUUID(e.groupUUID)
                        s.push({time: e.time, "type": e.eventType, "artist": e.bandName})
                    })
                    setSlots(s)
                    console.log(s)
                })
        }
        if (params.has("groupUUID")) {
            getEvent();
        }

    }, [])

    const selectVenueHandler = (e) => {
        const venueKey = e.target.value;
        const venueName = venueList.reduce((acc, v) => {
            if (v.venueKey === venueKey) {
                acc = v.name;
            }
            return acc;
        }, "")
        setVenueKey(venueKey)
        setVenueName(venueName)
    }

    if (!user.loggedIn) {
        return <Card>
            <CardHeader title={"Event Editor"} />
            <CardContent>
                <Alert severity={"warning"}>You must be logged in to create/edit events.</Alert>
            </CardContent>
        </Card>
    }

    if (success) {
        return <Card>
            <CardHeader title={"Event Saved"} />
            <CardContent>
                {dateToHumanDateStr(new Date(event.date+"T12:00"))} - {event.venueName}
                {event.slots.map(s => <div>{display24HourTime(s.time)} {s.artist}</div>)}
                <Button size={"small"} href={"/event/edit?groupUUID=" + event.groupUUID}>Edit</Button>
                <br />
                <br />
                <br />
                <div><Link href={"/"}>Main Calendar (Home Page)</Link></div>
                <div><Link href={"/account/events"}>All Events I Created</Link></div>
            </CardContent>
        </Card>
    }

    return <Grid container>
        <Grid item xs={12} >
            <Snackbar anchorOrigin={{vertical:"top",horizontal:"center"}} open={snackBarOpen} message={snackBarMessage} autoHideDuration={2000} onClose={() => setSnackBarOpen(false)}/>
            <h1>Add Event</h1>
            <Stepper activeStep={activeStep} orientation={"vertical"}>
                <Step>
                    <StepLabel>
                        Venue
                    </StepLabel>
                    <StepContent>
                        <Select value={venueKey} displayEmpty onChange={selectVenueHandler}>
                            <MenuItem key="default" value={""}>Select</MenuItem>
                            {venueList.map(v => <MenuItem value={v.venueKey}>{v.name}</MenuItem>)}
                        </Select>
                        <div style={{margin: "20px 0 20px 0"}}>
                            Venue not listed? <Link href={"/venue/add"}>Add New Venue Here</Link>
                        </div>
                        <StepControl waiting={venueKey === ""}/>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Date</StepLabel>
                    <StepContent>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateCalendar value={dayjs(date)} onChange={(v)=>setDate(v.format("YYYY-MM-DD"))} style={{margin: 0}}/>
                        </LocalizationProvider>
                        <StepControl stepComplete={venueName.length > 3} />
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Performers</StepLabel>
                    <StepContent>
                        {slots.map((slot,i) =>
                            <CalendarEventSlot slot={slot} key={i} i={i} setSlots={setSlots} slots={slots}/>
                        )}
                        <Button startIcon={<AddIcon />} onClick={() => setSlots(slots.concat([defaultSlot]))}>Add another</Button>
                        <StepControl stepComplete={venueName.length > 3} />

                    </StepContent>
                </Step>
                {/*<Step>*/}
                {/*    <StepLabel>Extras</StepLabel>*/}
                {/*    <StepContent>*/}
                {/*        <p>Optional: Add a link to Instagram Post (Stories not supported)</p>*/}
                {/*        <TextField value={insta} label={"Instagram Post URL"} onChange={e => setInsta(e.target.value)}/>*/}
                {/*        <StepControl />*/}
                {/*    </StepContent>*/}
                {/*</Step>*/}
                <Step>
                    <StepLabel>Review</StepLabel>
                    <StepContent>
                        <p>{venueName}</p>
                        <p>{date}</p>
                        {slots.map(s => <p>{display24HourTime(s.time)} - {s.artist}</p>)}
                        <StepControl />
                    </StepContent>
                </Step>
            </Stepper>
        </Grid>
    </Grid>
}