const api_host = process.env.REACT_APP_API_HOST;

export const getEvents = (setter, jti = undefined) => {
    // const existing = localStorage.getItem("events")
    // if (existing === null) {
    //     setter([])
    // } else {
    //     setter([])
    //     //setter(JSON.parse(existing))
    // }
    setter([])

    let url = api_host + "/?action=Calendar"
    if (jti) {
        url = url + "&jti=" + jti
    }

    fetch(url)
        .then(res => res.json())
        .then(events => {
            //localStorage.setItem("events", JSON.stringify(events))
            setter(events)
        })
}


export const mapEvents = (events) => {

    if (!events) {
        return [];

    }
    const map = {};

    for (let e of events) {
        const{ venue, venueKey, band, type, danceable, date, time, tag, bandKey, venmo,link,insta,isOwner,groupUUID } = e;
        if (!map.hasOwnProperty(date)) {
            map[date] = {date: date, data: []};
        }
        if (!map[date].data.hasOwnProperty(venueKey)) {
            map[date].data[venueKey] = {venue: venue, venueKey: venueKey, isOwner: isOwner, groupUUID: groupUUID, data: []};
        }
        map[date].data[venueKey].data.push({time: time, band: band, type:type, danceable: danceable, tag: tag, bandKey: bandKey, venmo: venmo, link: link, insta: insta})
    }

    let output = [];
    for (let dateStr of Object.keys(map)) {
        let eventDay = {date: dateStr, data: []};
        for (let venueKey of Object.keys(map[dateStr].data)) {
            eventDay.data.push(map[dateStr].data[venueKey])
        }
        output.push(eventDay);
    }
    return output;
}