import {AlertTitle, Card, CardActions, CardContent, CardHeader, TextField, Typography} from "@mui/material";
import {api_host, getPrivateToken} from "../fetch.js"
import UserContext from "./UserContext.jsx";
import {useContext, useEffect, useState} from "react";
import Alert from "@mui/material/Alert";
import {copyToClipboard} from "./Event.jsx";
import Button from "@mui/material/Button";
import {validate} from "uuid";

const calendarURL = (api_host, privateToken) => `${api_host}/?action=iCalFeed&token=${privateToken}`

const noAccess = <Card>
    <CardHeader title={"My Account"} />
    <CardContent>
        <Alert severity={"error"}>You must be logged in for this page</Alert>
    </CardContent>
</Card>

export const CalendarIntegration = (props) => {
    const user = useContext(UserContext)
    const [token, setToken] = useState(null)
    const {jti} = user;

    useEffect(() => {
        const getToken = () => {
            getPrivateToken(jti)
                .then(token => {
                    if (validate(token))
                        setToken(token)
                    else
                        setToken(false)
                })
        }
        getToken()
    },[])

    if (!jti) {
        return noAccess
    }

    const iCalURL = calendarURL(api_host, token)
    return <Card>
        <CardHeader title={"Calendar Integration"} />
        <CardContent>
            <Typography variant={"body2"}>Wanna see all of your starred events on your favorite calendar app? Paste the link below into your app's calendar settings.</Typography>
            <Alert severity={"warning"} icon={false}>
                <AlertTitle>Privacy Warning</AlertTitle>
                <Typography variant={"body2"}><em>Anyone with this link can see all of the events you have added a star to. Do not share.</em></Typography>
            </Alert>
            {validate(token) && <pre>{iCalURL}</pre>}
            {token === null && <pre>Generating Link</pre>}
            {token === false && <pre>Unable to generate Link</pre>}
            <CardActions>
                <Button variant={"contained"} disabled={!validate(token)} onClick={(e) => copyToClipboard(e, iCalURL, user)}>Copy Link</Button>
            </CardActions>
            <Alert severity={"info"} icon={false}>
                <AlertTitle>Pro Tip</AlertTitle>
                <Typography variant={"body2"}><em>It's important to paste the link directly into your calendar app, if you open this link and add the resulting downloaded (.ics) calender file, your calendar will not receive updates.</em></Typography>
            </Alert>
        </CardContent>
    </Card>
}