import {useContext, useEffect, useState} from "react";
import {eventStatusUpdate, pendingEventsGet} from "../admin-fetch.js";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import UserContext from "./UserContext.jsx";
import {CalendarEventList} from "../shared/class/event.js";
import {CardActions, CardHeader, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {toYYYYMMDD} from "../shared/date.js";
import Switch from "@mui/material/Switch";

const NoEvents = () => <Card>
    <CardHeader title={"No Events Found"} />
    <CardContent>
        No events found
    </CardContent>
</Card>

export const AdminPage = () => {

    const user = useContext(UserContext)
    const [pendingEvents, setPendingEvents] = useState([])
    const [displayPastEvents, setDisplayPastEvents] = useState(false)

    const loadPendingEvents = () => pendingEventsGet(user.jti)
        .then(events => CalendarEventList.fromRows(events))
        .then(list => setPendingEvents(list))

    useEffect(() => {
        loadPendingEvents()
    }, [])


    const today = toYYYYMMDD(new Date())
    let eventListFiltered;
    if (displayPastEvents) {
        eventListFiltered = pendingEvents.filter(e => e.date < today)
    } else {
        eventListFiltered = pendingEvents.filter(e => e.date >= today)
    }
    const eventStatusHandler = (groupUUID, status) => {
        eventStatusUpdate(user.jti, groupUUID, status)
            .then(res => {
                user.setSnackBar({open: true, message:  res ? "Updated" : "Failed"})
                loadPendingEvents()
            })
    }

    const EventCardContainer = () => {
        if (eventListFiltered.length === 0) {
            return <NoEvents />
        }
        return <EventCards />
    }

    const EventCards = () => eventListFiltered.map(e =>
        <Card>
                <CardHeader title={e.localDate()}/>
                <CardContent>
                    <div><em>{e.creatorName}</em></div>
                    <div><em>{e.status}</em></div>
                    {e.venueName}
                    {e.slots.map((s,i) =>
                        <div key={i}>{s.localTime()} - {s.artist} ({s.type})</div>
                    )}
                </CardContent>
                <CardActions>
                    <Button variant={"contained"}
                            onClick={() => eventStatusHandler(e.groupUUID, 'approved')}>Approve</Button>
                    <Button color={"error"} onClick={() => eventStatusHandler(e.groupUUID, 'deleted')}>Delete</Button>
                </CardActions>
            </Card>
    )

    return <div>
        <Card>
            <CardHeader title={"Events created by users"}/>
        </Card>
        <br/>
        <div>
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
                <Typography>Past</Typography>
                <Switch checked={!displayPastEvents} onClick={e => setDisplayPastEvents(!e.target.checked)}/>
                <Typography>Upcoming</Typography>
              </Stack>
        </div>
        <EventCardContainer />
    </div>
}