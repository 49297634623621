import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {dateToHumanDateStr} from "../shared/date.js";
import {Card, CardContent, Chip} from "@mui/material";
import Grid from "@mui/material/Grid";
import {sliceIn2} from "../shared/transform.js";
import Divider from "@mui/material/Divider";
import {BackButton} from "./BackButton.jsx";
import {getEvents} from "../EventLoader.jsx";

const api_host = process.env.REACT_APP_API_HOST;

const Venue = () => {

    let [events, setEvents] = useState([]);
    const [venue, setVenue] = useState({});
    const params = useParams();
    const venue_key = params.venue_key;

    events = events.filter(e => e.venueKey === venue_key);

     const getVenue = (artistKey) => fetch(api_host + "/?action=VenueGet&venue_key=" + venue_key)
        .then(res => res.json())
        .then(venue => {
           setVenue(venue)
        });

    useEffect(() => {
        //ensure a refresh every hour
        setInterval(() => getEvents(setEvents), 300 * 1000);
        getEvents(setEvents);
        getVenue(venue_key)
    }, [venue_key])

    return <Grid container>
            <Grid item xs={12}>
                <BackButton />
                <Card>
                    <CardContent>
                        <span style={{fontSize: "150%"}}>{venue.name}</span>

                        <Divider/>
                         <h6>Upcoming Shows</h6>
                        <Grid container>
                            {sliceIn2(events).map(halfEvents =>
                                <Grid item xs={12} md={6} style={{marginBottom: "10px"}}>
                                    {halfEvents.map(e =>
                                        <div style={{paddingBottom: "10px"}}>
                                            <div>{dateToHumanDateStr(new Date(e.date + " 12:00:00"))}</div>
                                            <div>{e.time} {e.band}</div>
                                        </div>)}
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
}

export default Venue;